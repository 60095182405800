/** 活动分类：1-充值送，3-救济金，5-特定活动 */
export enum ActivityType {
  CHARGE = 1,
  SAVE_BONUS = 3,
  SPECIAL = 5,
}

/** 充值活动选项 */
export type TActivityItem = {
  // 活动id
  activityId: number
  // 图片
  pcPicture: string
  // 图片
  h5Picture: string
  // 标题
  title: string
  // 内容
  content: string
  // 最小充值金额
  min: string
  // 最大充值金额
  max: string
  // 活动分类：1-充值送，3-救济金，5-特定活动
  activityType: 1 | 3 | 5
  // 描述
  description: string
}

/** 圣诞节活动充值者页面 */
export enum ChristmasUserPageEnum {
  DEPOSIT,
  JOIN,
  BONUS,
}

export interface CustomForm {
  language: string
  required: boolean
  type: string
  value: string
  placeholder: string
}
